.payBtn {
    padding: 1.5rem;
    width: 600px;
    font-size: 1.5rem;

    border-radius: 1.5rem;
    border-color: white;
    border-width: 5px;
    border-style: solid;

    color: #ffffff;

    background-color: #ffa500;
    background-image: linear-gradient(to bottom, #cc7a00, #ffa500);

    &:hover {
        color: #d3d3d3;
    }
}

.clicable {
    cursor: pointer;
}

.cohortBox {
    position: absolute;
    top: 10px;
    right: 10px;
    align-content: center;

    // background-color: #d3d3d3;
    border-radius: 1.5rem;
    border-color: white;
    border-width: 5px;
    border-style: solid;

    &> a {
        text-decoration: none;
    }
}

.cohortTop {
    padding: 2rem;
    color: black;

    border-bottom-color: white;
    border-bottom-width: 5px;
    border-bottom-style: solid;
}

.cohortBottom {
    padding: 1rem;
    color: #ffffff;
    font-size: 1.2rem;

    background-color: #ffa500;
    background-image: linear-gradient(to bottom, #cc7a00, #ffa500);

    border-bottom-left-radius: calc(1.5rem - 5px);
    border-bottom-right-radius: calc(1.5rem - 5px);

    &:hover {
        color: #d3d3d3;
    }
}
