.Btn {
    padding: 1.5rem;
    width: 600px;
    font-size: 1.5rem;

    border-radius: 1.5rem;
    border-color: white;
    border-width: 5px;
    border-style: solid;

    color: #ffffff;

    background-color: #ffa500;
    background-image: linear-gradient(to bottom, #cc7a00, #ffa500);

    &:hover {
        color: #d3d3d3;
    }

    cursor: pointer;
    text-decoration: none;
}
