/* html,
body {
    height: 100%;
}

body {
    margin: 0;
    background-color: #1a1a1a;
    color: white;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, #2e2e2e, #707070);
} */

.app-logged-out {
    text-align: center;
    background-color: #1a1a1a;
    background-image: linear-gradient(to bottom, #2e2e2e, #707070);
    min-height: 100vh
}

.nav-link {
    align-content: center;
    border-width: 1px;
    border-color: #ffa500;
    background-color: #ffa500;
    background-image: linear-gradient(to bottom, #2e2e2e, #707070);
    color: #ffffff;
    border-radius: 8px;
    padding: 0 10px;
    font-size: 14px;
    text-decoration: none;
    height: 32px;
    width: 140px;
    margin-left: 15px;
    transition: border-color .3s, background-color .3s, transform .3s, color .3s;
}

.nav-link:hover {
    background-color: #ffa500;
    color: #d3d3d3;
    text-decoration: none;
}

.header {
    margin-bottom: 40px;
}

.header h1 {
    font-size: 3em;
    margin: 0;
    padding-bottom: 50px;
}

.cta-button {
    background-color: #ffa500;
    background-image: linear-gradient(to bottom, #cc7a00, #ffa500);
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.5em;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: none;
    transition: border-color .3s, background-color .3s, transform .3s, color .3s;
}

.cta-button:hover {
    background-color: #e69500;
    color: #d3d3d3;
}

.main {
    margin-top: 100px;
}

h2 {
    font-size: 2em;
    padding-top: 150px;
}

.coins {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
}

.coin {
    background-color: #444;
    padding: 30px;
    border-radius: 10px;
    width: 30%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin: 20px;
}

.coin img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.coin h3 {
    font-size: 1.5em;
    margin: 10px 0;
}

.coin .change {
    font-size: 2em;
    color: #4caf50;
}

.coin .change.negative {
    color: #f44336;
}
