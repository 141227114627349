@font-face {
  font-family: Proto-Mono;
  src: url("../assets/fonts/Proto Mono Regular.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

html,:host {
  font-family: Proto-Mono;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent
}

html,
body {
  margin: 0;
  line-height: inherit;
  font-family: Proto-Mono;
  height: 100%;
}

body {
  margin: 0;
  color: white;
  justify-content: center;
  align-items: center;
}
